import { useCallback, useEffect, useMemo, useState } from 'react';
import { faShuffle } from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpFromBracket, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { UserOnboardingActionType } from '@remento/types/user';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { isFormDirty, submitForm } from '@/modules/form/form';
import { setInputValue } from '@/modules/form/input';
import { PollShareDialogContainer } from '@/modules/sharing/containers/PollShareDialog.container';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';
import { openShareSheet, showShareSheetToast } from '@/utils/share-sheet';
import { secureUuid } from '@/utils/uuid';

import { CreatePollCelebration } from '../components/CreatePollCelebration/CreatePollCelebration.js';
import { CreatePollEdit } from '../components/CreatePollEdit/CreatePollEdit.js';
import { CreatePollOptions } from '../components/CreatePollOptions/CreatePollOptions.js';
import { CreatePollShare } from '../components/CreatePollShare/CreatePollShare.js';
import { PollOptionItem } from '../components/PollOptionItem/PollOptionItem.js';
import { PollOptionShuffler } from '../components/PollOptionShuffler/PollOptionShuffler.js';
import { PollOption, usePollOptions } from '../hooks/poll-options.hook.js';
import { createPollEditForm } from '../poll-edit.form.js';

interface CreatePollSelectionState {
  type: 'selection';
}

interface CreatePollEditState {
  type: 'edit';
}

interface CreatePollDiscardEditState {
  type: 'discard-edit';
  action: 'close' | 'back';
}

interface CreatePollDiscardShufflingState {
  type: 'discard-shuffling';
}

interface CreatePollOnboardingState {
  type: 'onboarding';
}

interface CreatePollShareState {
  type: 'share';
  pollId: string;
  expireOn: number;
  shareOpen: boolean;
}

interface CreatePollCelebrationState {
  type: 'celebration';
}

type CreatePollState =
  | CreatePollSelectionState
  | CreatePollEditState
  | CreatePollDiscardShufflingState
  | CreatePollDiscardEditState
  | CreatePollOnboardingState
  | CreatePollShareState
  | CreatePollCelebrationState;

type AddPromptsDialogContainerProps = {
  projectId: string;
  onPromptsAdded?: () => void;
  onBack: () => void;
  onClose: () => void;
};

export function CreatePollContainer({ projectId, onBack, onClose }: AddPromptsDialogContainerProps) {
  const isMobile = useIsMobileViewport();

  const {
    pollService,
    pollCacheService,
    pollAnalyticsService,
    entityCacheManagerService,
    userService,
    userAnalyticsService,
  } = useServices();
  const user = useUser();

  const { pollOptions, shuffling, shuffle, setPollOptions } = usePollOptions(projectId);

  const [state, setState] = useState<CreatePollState>({ type: 'selection' });
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const [form, setForm] = useState(() => createPollEditForm());

  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;
  const personQuery = usePersonQuery(project?.notifications.recipientPersonIds[0]);
  const firstName = personQuery.data?.name?.first;

  const currentTitle = useMemo(() => {
    switch (state.type) {
      case 'selection':
        return 'Choose poll questions';
      case 'edit':
        return 'Edit question';
      case 'onboarding':
        return 'Your poll will be shared';
    }
  }, [state.type]);

  const resetDialog = useCallback(() => {
    setForm(createPollEditForm());
    setState({ type: 'selection' });
  }, []);

  const handleDiscardChanges = useCallback(() => {
    setForm(createPollEditForm());

    setState({ type: 'selection' });
    shuffle();
  }, [shuffle]);

  const handleDiscardEdit = useCallback(() => {
    if (state.type === 'discard-edit' && state.action === 'close') {
      onClose();
    }

    if (state.type === 'discard-edit' && state.action === 'back') {
      onBack();
    }
    resetDialog();
  }, [onBack, onClose, resetDialog, state]);

  const handleEditPollOption = useCallback(
    (pollOption: PollOption) => {
      setState({ type: 'edit' });
      setEditingIndex(pollOptions.findIndex((o) => o.templateId == pollOption.templateId));
      setInputValue(form, 'question', pollOption.question);
    },
    [form, pollOptions],
  );

  const handleCreatePoll = useCallback(async () => {
    const pollId = secureUuid();

    await pollCacheService.createPoll({
      id: pollId,
      projectId,
      prompts: pollOptions.map(({ templateId, tagId, question, customized }) => ({
        id: secureUuid(),
        template: customized ? null : { id: templateId, tagIds: [tagId] },
        text: question,
      })),
    });
    pollAnalyticsService.onPollCreated();

    setForm(createPollEditForm());

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return { pollId, expireOn: tomorrow.getTime() };
  }, [pollAnalyticsService, pollCacheService, pollOptions, projectId]);

  const handlePublish = useCallback(async () => {
    if (user == null) {
      // This will never happen.
      return;
    }

    try {
      if (user.onboardingHistory.pollCreated?.done === true) {
        const { pollId, expireOn } = await handleCreatePoll();
        setState({ type: 'share', pollId, expireOn, shareOpen: false });
        return;
      }

      setState({ type: 'onboarding' });
    } catch (error) {
      captureException(error, true);
      toast('Failed to create the poll', 'root-toast', 'error');
    }
  }, [user, handleCreatePoll]);

  const handleConfirmShare = useCallback(async () => {
    if (user == null) {
      // This will never happen.
      return;
    }

    try {
      await entityCacheManagerService.mutate(
        userService.createSetUserOnboardingHistoryMutation(user, UserOnboardingActionType.POLL_CREATED),
      );
      userAnalyticsService.onOnboardingProgress(UserOnboardingActionType.POLL_CREATED);
      const { pollId, expireOn } = await handleCreatePoll();
      setState({ type: 'share', pollId, expireOn, shareOpen: false });
    } catch (error) {
      captureException(error, true);
      toast('Failed to create the poll', 'root-toast', 'error');
    }
  }, [entityCacheManagerService, handleCreatePoll, user, userService]);

  const handleSaveEdit = useCallback(() => {
    if (editingIndex == null) {
      return;
    }

    submitForm(form, ({ question }) => {
      setPollOptions(
        pollOptions.map((option, index) => {
          if (index == editingIndex) {
            return {
              ...option,
              customized: true,
              question,
            };
          }
          return option;
        }),
      );
      setState({ type: 'selection' });
    });
  }, [editingIndex, form, pollOptions, setPollOptions]);

  const handleClose = useCallback(() => {
    const isDirty = isFormDirty(form);
    if (isDirty) {
      setState({ type: 'discard-edit', action: 'close' });
      return;
    }

    if (state.type === 'share') {
      setState({ type: 'celebration' });
      return;
    }

    onClose();
  }, [onClose, form, state.type]);

  const handleShare = useCallback(async () => {
    if (state.type != 'share') {
      return;
    }

    if (isMobile) {
      const shareLinkPromise = pollService.getPollShareLink(state.pollId).then((s) => s.link);
      showShareSheetToast(await openShareSheet({ url: shareLinkPromise }));
      handleClose();
    } else {
      setState({ ...state, shareOpen: true });
    }
  }, [handleClose, isMobile, pollService, state]);

  const handleShuffle = useCallback(async () => {
    const isDirty = isFormDirty(form);
    if (isDirty) {
      setState({ type: 'discard-shuffling' });
      return;
    }

    await shuffle();
  }, [form, shuffle]);

  const handleBack = useCallback(() => {
    const isDirty = isFormDirty(form);
    if (isDirty) {
      setState({ type: 'discard-edit', action: 'back' });
      return;
    }

    onBack();
  }, [form, onBack]);

  useEffect(() => {
    if (state.type !== 'celebration') {
      return;
    }
    const timeout = setTimeout(() => {
      onClose();
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [onClose, state.type]);

  // Analytics - todo

  const shareDialogOpen = state.type === 'share' && state.shareOpen === true;

  return (
    <>
      <RMDialog.Root
        open={state.type !== 'onboarding' && state.type !== 'celebration' && !shareDialogOpen}
        variant={isMobile ? 'full-screen' : 'regular'}
      >
        <RMDialog.Content>
          <RMDialog.Header
            centerTitle={true}
            title={currentTitle}
            leftAdornment={
              state.type === 'selection' ? (
                <RMIconButton
                  icon={faChevronLeft}
                  tooltip={null}
                  backgroundColor="transparent"
                  stateColor="darken-neutral"
                  onClick={handleBack}
                />
              ) : null
            }
            rightAdornment={state.type !== 'edit' ? <RMCloseButton onClick={handleClose} /> : null}
          ></RMDialog.Header>

          <RMDialog.Body>
            {(state.type === 'selection' || state.type === 'onboarding') && (
              <CreatePollOptions loading={pollOptions.length === 0}>
                <PollOptionShuffler key={`${shuffling}`} show={shuffling} />
                {pollOptions.map((pollOption, i) => (
                  <PollOptionItem
                    key={i + String(shuffling)}
                    label={`Option ${i + 1}`}
                    pollOption={pollOption}
                    animate={shuffling ? (i === 0 ? 'ttb' : 'btt') : false}
                    onClick={() => handleEditPollOption(pollOption)}
                  />
                ))}
              </CreatePollOptions>
            )}
            {state.type === 'edit' && <CreatePollEdit form={form} />}
            {state.type === 'share' && <CreatePollShare firstName={firstName ?? ''} expireOn={state.expireOn} />}
          </RMDialog.Body>

          <RMDialog.Footer>
            {(state.type === 'selection' || state.type === 'onboarding') && (
              <>
                <RMButton
                  leftIcon={faShuffle}
                  background="neutral"
                  fullWidth
                  disabled={shuffling}
                  onClick={handleShuffle}
                >
                  Shuffle options
                </RMButton>

                <RMButton background="primary" fullWidth disabled={shuffling} autoLoading onClick={handlePublish}>
                  Publish poll
                </RMButton>
              </>
            )}
            {state.type === 'edit' && (
              <RMButton background="primary" fullWidth onClick={handleSaveEdit}>
                Save
              </RMButton>
            )}

            {state.type === 'share' && (
              <RMButton leftIcon={faArrowUpFromBracket} background="primary" fullWidth onClick={handleShare}>
                Share poll
              </RMButton>
            )}
          </RMDialog.Footer>

          <RMConfirmationModal
            open={state.type === 'discard-edit'}
            title="Discard changes?"
            message="By closing this page, you will lose the edits you’ve made."
            confirmLabel="Yes, discard"
            type="danger"
            onConfirm={handleDiscardEdit}
            onCancel={() => {
              setState({ type: 'selection' });
            }}
          />

          <RMConfirmationModal
            open={state.type === 'discard-shuffling'}
            title="Discard changes?"
            message="By shuffling these poll options, you will discard the edits you’ve made."
            confirmLabel="Yes, shuffle"
            type="danger"
            onConfirm={handleDiscardChanges}
            onCancel={() => {
              setState({ type: 'selection' });
            }}
          />
        </RMDialog.Content>
      </RMDialog.Root>

      {state.type === 'onboarding' && (
        <RMDialog.Root open={state.type === 'onboarding'}>
          <RMDialog.Content>
            <RMDialog.Header
              title="Your poll will be shared"
              rightAdornment={<RMCloseButton onClick={() => setState({ type: 'selection' })} />}
            ></RMDialog.Header>

            <RMDialog.Body>
              <RMText type="sans" size="xs" color="on-surface-primary">
                All collaborators on this project will be automatically invited to vote.
              </RMText>
            </RMDialog.Body>

            <RMDialog.Footer>
              <RMButton background="primary" fullWidth={isMobile} autoLoading onClick={handleConfirmShare}>
                Publish poll
              </RMButton>
            </RMDialog.Footer>
          </RMDialog.Content>
        </RMDialog.Root>
      )}

      {state.type === 'share' && (
        <PollShareDialogContainer
          open={state.shareOpen}
          projectId={projectId}
          pollId={state.pollId}
          onClose={handleClose}
        />
      )}
      {state.type === 'celebration' && <CreatePollCelebration />}
    </>
  );
}
