import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { PromptStatus } from '@remento/types/project';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMLoader } from '@/components/RMLoader/RMLoader';
import { RMPage } from '@/components/RMPage';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useAutohideIntercomBubble } from '@/hooks/useAutohideIntercomBubble';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useBooleanQueryParam, useStringQueryParam } from '@/hooks/useQueryParam';
import { AppLayout } from '@/layouts/AppLayout';
import { MobilePageHeaderContainer } from '@/modules/navbar/containers/MobilePageHeaderContainer';
import { setNavbarProjectId, useNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { useShowPaywall } from '@/modules/paywall';
import { PollListContainer } from '@/modules/poll/containers/PollList.container';
import { PollVotingContainer } from '@/modules/poll/containers/PollVoting.container';
import {
  createPollVotingDialogPanelStore,
  openPollVotingDialog,
} from '@/modules/poll/states/poll-voting-dialog-panel.state';
import { PromptList } from '@/modules/project/components/PromptList';
import { PromptListRootRef } from '@/modules/project/components/PromptList/PromptListRoot';
import { AddPromptsDialogContainer } from '@/modules/project/containers/AddPromptsDialog.container';
import { ProjectBannerContainer } from '@/modules/project/containers/ProjectBanner.container';
import { PromptListContainer } from '@/modules/project/containers/PromptList.container';
import { PromptUpdateContainer } from '@/modules/project/containers/PromptUpdate.container';
import {
  createProjectDialogPanelStore,
  openAddPromptDialog,
} from '@/modules/project/states/project-dialog-panel.state';
import { createPromptDraftsStore } from '@/modules/project/states/prompt-drafts.state';
import { RementoPage } from '@/modules/routing';
import { ProjectShareButtonContainer } from '@/modules/sharing/containers/ProjectShareButton.container';
import { ProjectShareHeaderContainer } from '@/modules/sharing/containers/ProjectShareHeader.container.tsx';
import { useServices } from '@/Services';
import { useProjectPollsQuery } from '@/services/api/poll';
import { useFilterProjectPromptIds, useProjectQuery } from '@/services/api/project';

import { AddPromptMobile, PromptListWrapper } from './QuestionsPage.style';

function Questions() {
  const isMobile = useIsMobileViewport();

  const [openPromptPickerParam, setOpenPromptPickerParam] = useBooleanQueryParam('openPromptPicker');
  const [openPollCreatorParam, setOpenPollCreatorParam] = useBooleanQueryParam('openPollCreator');
  const [pollIdParam, setPollIdParam] = useStringQueryParam('pollId');

  // Services
  const { promptAnalyticsService } = useServices();
  useAutohideIntercomBubble();

  // Stores
  const dialogPanelStore = useMemo(() => createProjectDialogPanelStore(), []);
  const promptDraftsStore = useMemo(() => createPromptDraftsStore(), []);
  const pollVotingPanelStore = useMemo(() => createPollVotingDialogPanelStore(), []);
  const navbarStore = useNavbarStore();
  const projectId = useNavbarProjectId(navbarStore);

  // Entities
  const projectQuery = useProjectQuery(projectId);
  const hasTopics = (projectQuery.data?.configuration.topics.length ?? 0) > 0;
  const sentPromptsIds = useFilterProjectPromptIds(projectId, PromptStatus.SENT);
  const pendingPromptsIds = useFilterProjectPromptIds(projectId, PromptStatus.PENDING);
  const projectPollsIds = useProjectPollsQuery(projectId)?.data;

  const promptListRef = useRef<PromptListRootRef>(null);
  const hasNoPrompts =
    sentPromptsIds != null &&
    sentPromptsIds.length === 0 &&
    pendingPromptsIds != null &&
    pendingPromptsIds.length === 0 &&
    projectPollsIds != null &&
    projectPollsIds.length === 0;

  // Paywall
  const showPaywall = useShowPaywall(projectId);

  const handleAddPrompt = useCallback(async () => {
    if (showPaywall()) {
      return;
    }

    openAddPromptDialog(dialogPanelStore);
    promptAnalyticsService.onUpcomingPromptsNewPromptPressed('question');
  }, [dialogPanelStore, promptAnalyticsService, showPaywall]);

  const handlePromptsAdded = useCallback(() => {
    promptListRef.current?.scrollToBottom();
  }, []);

  // Open the prompt picker or poll creator
  useEffect(() => {
    // Always clear the query param after reading it to avoid issues when this effect re-runs.
    if (openPromptPickerParam) {
      setOpenPromptPickerParam(null);
      if (showPaywall()) {
        return;
      }
      openAddPromptDialog(dialogPanelStore);
      return;
    }

    if (openPollCreatorParam) {
      setOpenPollCreatorParam(null);
      if (showPaywall()) {
        return;
      }
      openAddPromptDialog(dialogPanelStore, 'poll');
      return;
    }

    if (pollIdParam != null) {
      setPollIdParam(null);
      if (showPaywall()) {
        return;
      }
      openPollVotingDialog(pollVotingPanelStore, pollIdParam);
      return;
    }
  }, [
    dialogPanelStore,
    openPollCreatorParam,
    openPromptPickerParam,
    pollIdParam,
    pollVotingPanelStore,
    setOpenPollCreatorParam,
    setOpenPromptPickerParam,
    setPollIdParam,
    showPaywall,
  ]);

  // Analytics
  useEffect(() => {
    promptAnalyticsService.onUpcomingPromptsArrived();
  }, [promptAnalyticsService]);

  if (!projectId) {
    return <PageLoader layout="navbar" />;
  }

  return (
    <RMPage.Root>
      <MobilePageHeaderContainer>
        <ProjectShareButtonContainer page="prompts" />
      </MobilePageHeaderContainer>
      <ProjectBannerContainer projectId={projectId} />
      <RMPage.Header>
        <RMPage.Title title="Prompts" />
        <RMPage.HeaderActions>
          <ProjectShareHeaderContainer projectId={projectId} page="prompts">
            <RMButton
              id="page-header-add-prompts-button"
              color="white"
              leftIcon={faPlus}
              background="primary"
              size="small"
              onClick={handleAddPrompt}
            >
              Add {hasTopics ? 'prompts' : 'photos'}
            </RMButton>
          </ProjectShareHeaderContainer>
        </RMPage.HeaderActions>
      </RMPage.Header>
      <RMPage.Content>
        {isMobile && (
          <AddPromptMobile>
            <RMButton color="white" background="primary" size="medium" onClick={handleAddPrompt}>
              Add prompts
            </RMButton>
          </AddPromptMobile>
        )}

        {(projectPollsIds == null || sentPromptsIds == null || pendingPromptsIds == null) && <RMLoader center={true} />}

        {projectPollsIds != null && projectPollsIds.length > 0 && (
          <>
            <RMText type="sans" size="s" bold color="on-surface-primary">
              Active prompt polls ({projectPollsIds.length})
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <PromptListWrapper>
              <PollListContainer projectId={projectId} pollVotingPanelStore={pollVotingPanelStore} />
            </PromptListWrapper>

            {((sentPromptsIds != null && sentPromptsIds.length > 0) ||
              (pendingPromptsIds != null && pendingPromptsIds.length > 0)) && (
              <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
            )}
          </>
        )}

        {sentPromptsIds != null && sentPromptsIds.length > 0 && (
          <>
            <RMText type="sans" size="s" bold color="on-surface-primary">
              {projectQuery.data?.configuration.timePeriod == 'PRESENT' ? 'Current' : 'In progress'} (
              {sentPromptsIds.length})
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <PromptListWrapper>
              <PromptListContainer
                projectId={projectId}
                status={PromptStatus.SENT}
                dialogPanelStore={dialogPanelStore}
              />
            </PromptListWrapper>

            {pendingPromptsIds != null && pendingPromptsIds.length > 0 && (
              <RMSpacer spacing={isMobile ? 'md' : '2xl'} direction="column" />
            )}
          </>
        )}

        {pendingPromptsIds != null && pendingPromptsIds.length > 0 && (
          <>
            <RMText type="sans" size="s" bold color="on-surface-primary">
              To be sent ({pendingPromptsIds.length})
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <PromptListWrapper>
              <PromptListContainer
                projectId={projectId}
                status={PromptStatus.PENDING}
                dialogPanelStore={dialogPanelStore}
              />
            </PromptListWrapper>
          </>
        )}

        {hasNoPrompts && <PromptList.Empty hasTopics={hasTopics} onAddPrompts={handleAddPrompt} />}

        <PollVotingContainer projectId={projectId} pollVotingPanelStore={pollVotingPanelStore} />
        <PromptUpdateContainer store={dialogPanelStore} />
        <AddPromptsDialogContainer
          projectId={projectId}
          panelStore={dialogPanelStore}
          promptDraftsStore={promptDraftsStore}
          onPromptsAdded={handlePromptsAdded}
        />
      </RMPage.Content>
    </RMPage.Root>
  );
}

export function QuestionsPage() {
  const params = useParams();
  const navbarStore = useNavbarStore();

  useEffect(() => {
    setNavbarProjectId(navbarStore, params.projectId ?? '');
  }, [navbarStore, params.projectId]);

  return (
    <RementoPage type="default">
      <AppLayout>
        <Questions />
      </AppLayout>
    </RementoPage>
  );
}
